<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="p-1">
        <b-row>
          <b-col
            v-if="$can('create', 'info')"
            class="col d-flex justify-content-end"
          >
            <b-button variant="primary" @click="addInformation">
              <feather-icon icon="PlusIcon" size="16" /> {{ $t("New") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refInformationsListTable"
        class="position-relative table-white-space"
        :items="fetchInformations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="updateInformation(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>
        <template #cell(name_en)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="updateInformation(data.item)"
          >
            {{ data.item.name_en }}
          </b-link>
        </template>
        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{
            data.item.created_at
              ? formatDateTime(data.item.created_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(updated_at)="data">
          {{
            data.item.updated_at
              ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
              : ""
          }}
        </template>

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            v-if="$can('update', 'info')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="updateInformation(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('delete', 'info')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteInformation(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalInformations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Add Information Modal -->
    <add-information-modal @refetch-data="refetchData" />

    <!-- Update Information Modal -->
    <update-information-modal
      :information-detail.sync="informationDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import useInformationList from "@/views/frontend/information/useInformationList";
import frontendStoreModule from "@/views/frontend/frontendStoreModule";
import baseUrl from "@/libs/base-url";

export default {
  components: {
    AddInformationModal: () => import("./AddInformationModal.vue"),
    UpdateInformationModal: () => import("./UpdateInformationModal"),
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      informationDetail: null,
    };
  },
  methods: {
    addInformation() {
      this.$bvModal.show("modal-add-information");
    },
    deleteInformation(informationId) {
      this.$swal({
        title: "Are you sure ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const informationData = {
            id: informationId,
          };
          store
            .dispatch("frontend/deleteInformation", informationData)
            .then((response) => {
              if (response.data.code == "00") {
                this.$swal({
                  icon: "success",
                  title: "Deleted!",
                  text: "Your item has been deleted.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.refetchData();
              }
            });
        }
      });
    },

    updateInformation(datas) {
      this.informationDetail = datas;
      this.$bvModal.show("modal-update-information");
    },
  },
  setup() {
    const FRONTEND_MODULE_NAME = "frontend";
    if (!store.hasModule(FRONTEND_MODULE_NAME))
      store.registerModule(FRONTEND_MODULE_NAME, frontendStoreModule);
    onUnmounted(() => {
      if (store.hasModule(FRONTEND_MODULE_NAME))
        store.unregisterModule(FRONTEND_MODULE_NAME);
    });
    const {
      fetchInformations,
      tableColumns,
      perPage,
      currentPage,
      totalInformations,
      dataMeta,
      refInformationsListTable,
      refetchData,
      resolveStatus,
    } = useInformationList();

    return {
      fetchInformations,
      tableColumns,
      perPage,
      currentPage,
      totalInformations,
      dataMeta,
      refInformationsListTable,
      refetchData,
      resolveStatus,
      numberFormat,
      formatDateTime,
      baseUrl,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>